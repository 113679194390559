var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BContainer',{staticClass:"my-5"},[_c('h3',{staticClass:"mb-3 text-center text-light"},[_vm._v("Admin Register")]),_c('BCard',{staticClass:"mx-auto",staticStyle:{"max-width":"350px"},attrs:{"bg-variant":"dark","border-variant":"warning","text-variant":"light"}},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.register)}}},[_c('ValidationProvider',{staticClass:"form-group",attrs:{"tag":"div","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"username"}},[_vm._v("Username")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.username),expression:"formData.username"}],staticClass:"form-control bg-dark text-white border-secondary",attrs:{"name":"username","type":"text","placeholder":"username"},domProps:{"value":(_vm.formData.username)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formData, "username", $event.target.value)}}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('ValidationProvider',{staticClass:"form-group",attrs:{"tag":"div","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"email"}},[_vm._v("Email Address")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.email),expression:"formData.email"}],staticClass:"form-control bg-dark text-white border-secondary",attrs:{"name":"email","type":"email","placeholder":"Example@example.com"},domProps:{"value":(_vm.formData.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formData, "email", $event.target.value)}}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('ValidationProvider',{staticClass:"form-group",attrs:{"tag":"div","rules":"required|password:6,23|confirmed:@confirmation"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"password"}},[_vm._v("Password")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.password),expression:"formData.password"}],staticClass:"form-control bg-dark text-light border-secondary",class:{ 'is-invalid border-danger': errors != '' },attrs:{"name":"password","type":"password","placeholder":"******"},domProps:{"value":(_vm.formData.password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formData, "password", $event.target.value)}}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('ValidationProvider',{staticClass:"form-group",attrs:{"tag":"div","name":"confirmation","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"confirm"}},[_vm._v("Confirm Password")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.confirm),expression:"confirm"}],staticClass:"form-control bg-dark text-light border-secondary",class:{ 'is-invalid border-danger': errors != '' },attrs:{"type":"password","placeholder":"******"},domProps:{"value":(_vm.confirm)},on:{"input":function($event){if($event.target.composing){ return; }_vm.confirm=$event.target.value}}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('br'),_c('button',{staticClass:"w-100 btn btn-lg btn-primary",attrs:{"type":"submit"}},[_vm._v("Register")])],1)]}}])})],1),(_vm.error)?_c('Alert',{staticClass:"mt-3",attrs:{"variant":"danger","message":_vm.error}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }